import React from 'react'
import { NavbarComponent, BannerComponent, FooterComponent } from "./../components"
import { LandingContent } from './../contents'

const landingPage = () => {
  return (
    <>
      <NavbarComponent />
      <BannerComponent />
      <LandingContent />
      <FooterComponent />
    </>
  )
}

export default landingPage