import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

function ModalSyllabusSql() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSignup = () => {
    const message = "Halo saya mau daftar bootcamp sql fundamental";
    window.open(`https://wa.me/6285234637956?text=${message}`, '_blank');
  }

  return (
    <>
      <div className="col-lg-6 col-sm-6 mb-2 mt-2"> 
        <div className="card shadow rounded-lg h-100" onClick={handleShow}>
          <div className="card-body w-100 bg-white rounded-lg p-4">
            <div className="text-center">
              <p><span className="fs-6 fw-bold strike"> Rp 800,000 </span><span className="fs-1 fw-semibold"> / bootcamp</span></p>
              <span className="badge badge-pill rounded-pill bg-info py-3 px-3 mb-5 mt-2"> Mulai dari Rp 450,000 / bootcamp </span>
              <h4 className="mb-3">Bootcamp SQL Dasar</h4>
            </div>
            <ul className="text-justify">
              <li className="mb-2 fw-medium text-secondary">Kursus ini mengutamakan SQL dasar dan mudah di pahami serta langsung dengan mentor</li>
              <li className="mb-2 fw-medium text-info fw-bold">Hanya 3 orang dalam 1 kelas, untuk memaksimalkan kualitas kelas</li>
              <li className="mb-2 fw-medium text-secondary">Mengutamakan teknis daripada teori dengan perbandingan 70% dan 30%</li>
              <li className="mb-2 fw-medium text-secondary">Materi belajar yang disusun secara sistematis dan terstruktur</li>
              <li className="mb-2 fw-medium text-secondary">Sertifikat keberhasilan setelah menyelesaikan bootcamp</li>
              <li className="mb-2 fw-medium text-info fw-bold">Pembelajaran secara online dengan 10x pertemuan</li>
            </ul>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Silabus Bootcamp</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Dimulai pada tanggal 16 Maret 2024</h6>
          <h6>Intro</h6>
          <ul>
            <li>Instalasi dan Configurasi</li>
            <li>Konsep Entity Relational Diagram (ERD)</li>
            <li>Konsep Normalisasi</li>
            <li>Pengenalan MySQL</li>
          </ul>
          <h6>Data Definition Language (DDL)</h6>
          <ul>
            <li>CREATE</li>
            <li>ALTER</li>
            <li>DROP</li>
          </ul>
          <h6>Data Manipulation Language (DML)</h6>
          <ul>
            <li>SELECT</li>
            <li>INSERT</li>
            <li>UPDATE</li>
            <li>DELETE</li>
          </ul>
          <h6>SELECT Statement (GROUP, AGGREGATE, WHERE)</h6>
          <h6>SELECT Multitabel</h6>
          <h6>Final Project</h6>
        </Modal.Body>
        <Modal.Footer>
          <button className="badge badge-pill rounded-pill bg-danger border-0 py-3 px-3" onClick={handleClose}>Close</button>
          <a className="badge badge-pill rounded-pill bg-info border-0 py-3 px-3 cursor-pointer" onClick={handleSignup}>Daftar Sekarang</a>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalSyllabusSql;