import React from 'react'

const footerComponent = () => {
  return (
    <div className="content">
      <section className="pb-2 pb-lg-5">
        <div className="container">
          <div className="row border-top border-top-secondary pt-7">
            <div className="col-lg-3 col-md-6 mb-4 mb-md-6 mb-lg-0 mb-sm-2 order-1 order-md-1 order-lg-1"><img className="mb-4" src="assets/svg/logo/new-matetrick.svg" width="250" alt="" /></div>
            <div className="col-lg-3 col-md-6 mb-4 mb-lg-0 order-3 order-md-3 order-lg-2">
              <p className="fs-2 mb-lg-4">Link Alternatif</p>
              <ul className="list-unstyled mb-0">
                <li className="mb-1"><a className="link-900 text-secondary text-decoration-none" href="#services">Layanan</a></li>
                <li className="mb-1"><a className="link-900 text-secondary text-decoration-none" href="#info">Info</a></li>
                <li className="mb-1"><a className="link-900 text-secondary text-decoration-none" href="#contact">Kontak</a></li>
                {/* <li className="mb-1"><a className="link-900 text-secondary text-decoration-none" href="#!">FAQ</a></li> */}
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 mb-lg-0 order-4 order-md-4 order-lg-3">
              <p className="fs-2 mb-lg-4">Media Sosial</p>
              <ul className="list-unstyled mb-0">
                <li className="mb-1"><a className="link-900 text-secondary text-decoration-none" href="https://www.instagram.com/matetrick_/" rel="noreferrer" target="_blank">Instagram</a></li>
                {/* <li className="mb-1"><a className="link-900 text-secondary text-decoration-none" href="#!">TikTok</a></li> */}
                <li className="mb-1"><a className="link-900 text-secondary text-decoration-none" href="https://api.whatsapp.com/send?phone=6285234637956&text=" rel="noreferrer" target="_blank">WhatsApp</a></li>
                {/* <li className="mb-1"><a className="link-900 text-secondary text-decoration-none" href="#!">Privacy Policy</a></li> */}
                {/* <li className="mb-1"><a className="link-900 text-secondary text-decoration-none" href="#!">Terms of Service</a></li> */}
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-6 mb-4 mb-lg-0 order-2 order-md-2 order-lg-4">
              <p className="fs-2 mb-lg-4">
                Mengetahui Anda selalu pada kesepakatan energi terbaik.</p>
              <div className="d-flex">
                <a href="https://api.whatsapp.com/send?phone=6285234637956&text=" rel="noreferrer" target="_blank" className="btn btn-warning w-100 fw-medium py-2">Kontak Kami</a>
              </div>
            </div>
          </div>
        </div>

      </section>

      <section className="text-center py-0">
        <div className="container">
          <div className="container border-top py-3">
            <div className="row justify-content-between">
              <div className="col-12 col-md-auto mb-1 mb-md-0">
              </div>
              <div className="col-12 col-md-auto mb-1 mb-md-0">
                <p className="mb-0">Copyright © 2023 All rights reserved | PT. Matetrick Inovasi Digital </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default footerComponent