import React from 'react'
import { NavbarComponent, BannerComponent, FooterComponent } from "../components"
import { ModalSyllabusJava, ModalSyllabusSql } from "../modals"

const labsPage = () => {
  const handleConsultation = () => {
    const message = "Halo saya mau konsultasi pemrograman nih!";
    window.open(`https://wa.me/6285234637956?text=${message}`, '_blank');
  }

  const handlePrivateCourse = () => {
    const message = "Halo saya mau private course pemrograman nih!";
    window.open(`https://wa.me/6285234637956?text=${message}`, '_blank');
  }

  return (
    <>
      <NavbarComponent />
      <section className="pt-7">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 text-md-start text-center py-6">
              <h1 className="mb-4 fs-9 fw-bold">Belajar Pemrograman? Matetrick Solusinya!</h1>
              <p className="mb-6 lead text-secondary">Matetrick merupakan startup yang bergerak dalam sektor jasa<br className="d-none d-xl-block" />pembuatan Website Apps, Mobile Apps, Desktop Apps dan seputar IT lainnya.</p>
              <div className="text-center text-md-start">
                <a className="btn btn-warning me-3 btn-lg" href="#services" role="button">Selengkapnya</a>
                {/* <a className="btn btn-link text-warning fw-medium" href="#!" role="button"><span className="fas fa-play me-2"></span>Watch the video </a> */}
              </div>
            </div>
            <div className="col-md-6 text-end"><img className="pt-5 pt-md-0 img-fluid w-80" src="assets/svg/undraw/banner-coder.svg" alt="" /></div>
          </div>
        </div>
      </section>

      <div className="content">
        <section className="pt-5 pt-md-9 mb-6" id="services">
          <div className="bg-holder z-index--1 bottom-0 d-none d-lg-block holder-bottom">
          </div>
          <div className="container">
            <h1 className="fs-9 fw-bold mb-5 text-center"> Apa aja sih layanan <br className="d-none d-xl-block" /> Matetrick Labs?</h1>
            <div className="row mb-5 d-flex justify-content-center">
              <div className="col-lg-6 col-sm-6 mb-2">
                <div className="card shadow rounded-lg h-100" onClick={handleConsultation}>
                  <div className="card-body w-100 bg-white rounded-lg p-4">
                    <div className="text-center">
                      <p><span className="fs-6 fw-bold strike"> Rp 40,000 </span><span className="fs-1 fw-semibold"> / jam</span></p>
                      <span class="badge badge-pill rounded-pill bg-primary py-3 px-3 mb-5 mt-2"> Mulai dari Rp 25,000 / jam </span>
                      <h4 className="mb-3">Layanan Konsultasi</h4>
                    </div>
                    <ul className="text-justify">
                      <li className="mb-2 fw-medium text-primary fw-bold">Bahasa pemrograman seperti java, javascript, PHP, C++ dll</li>
                      <li className="mb-2 fw-medium text-secondary">Konsultasi langsung dengan ahli pemrograman berpengalaman</li>
                      <li className="mb-2 fw-medium text-secondary">Diskusi tentang konsep-konsep pemrograman tertentu</li>
                      <li className="mb-2 fw-medium text-primary fw-bold">Saran dan panduan dalam memilih teknologi yang tepat</li>
                      <li className="mb-2 fw-medium text-secondary">Bimbingan dalam pengembangan strategi penyelesaian masalah</li>
                      <li className="mb-2 fw-medium text-secondary">Evaluasi dan umpan balik terhadap proyek atau kode yang sedang dikerjakan</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-sm-6 mb-2"> 
                <div className="card shadow rounded-lg h-100" onClick={handlePrivateCourse}>
                  <div className="card-body w-100 bg-white rounded-lg p-4">
                    <div className="text-center">
                      <p><span className="fs-6 fw-bold strike"> Rp 50,000 </span><span className="fs-1 fw-semibold"> / jam</span></p>
                      <span class="badge badge-pill rounded-pill bg-twitter py-3 px-3 mb-5 mt-2"> Mulai dari Rp 30,000 / jam </span>
                      <h4 className="mb-3">Private Course</h4>
                    </div>
                    <ul className="text-justify">
                      <li className="mb-2 fw-medium text-twitter fw-bold">Fleksibilitas jadwal sesuai dengan preferensi peserta</li>
                      <li className="mb-2 fw-medium text-secondary">Pembelajaran yang disesuaikan dengan kebutuhan dan tingkat pemahaman individu</li>
                      <li className="mb-2 fw-medium text-twitter fw-bold">Fokus pada topik atau bahasa pemrograman tertentu sesuai keinginan</li>
                      <li className="mb-2 fw-medium text-secondary">Konsultasi pribadi dan umpan balik langsung dari instruktur</li>
                      <li className="mb-2 fw-medium text-secondary">Materi belajar yang disesuaikan dengan minat dan tujuan belajar</li>
                      <li className="mb-2 fw-medium text-twitter fw-bold">Dukungan tambahan melalui komunikasi email atau chat selama masa kursus</li>
                    </ul>
                  </div>
                </div>
              </div>

              <ModalSyllabusJava/>

              <ModalSyllabusSql/>
            </div>

            <div className="text-center mt-5">
              <p className="fw-medium text-secondary"><span className="text-danger">* Perhatian! </span> Pembayaran hanya melalui rekening <span className="fw-bold">BCA 1200872181 a.n. Nor Rofikah</span> <br/>
              selain itu kami tidak bertanggung jawab!</p>
            </div>
            
          </div>
        </section>

        <section className="pt-3" id="testimonials">
          <div className="container">
            <h1 className="fw-bold fs-6 mb-3 text-center">Testimonial Matetrick</h1>
            <p className="mb-6 text-secondary text-center">Apa yang dirasakan mereka setelah bergabung dengan kami?</p>
            <div className="row">
              {/* Buatin card agar bisa slider */}
              <div className="col-md-3 mb-3">
                <div className="card shadow h-100">
                  {/* <img className="rounded" src="assets/img/testimonials/profiles.png" alt="" /> */}
                  <div className="card-body column">
                    <h3 className="fw-bold">Ri*****</h3>
                    <p className="text-secondary text-sm">"Untuk les privatnya lumayan, mentornya baik seandainya offline mungkin akan lebih seru."</p>
                    <div className="d-flex justify-content-center">
                      <span className="fas fa-star text-warning"></span>
                      <span className="fas fa-star text-warning"></span>
                      <span className="fas fa-star text-warning"></span>
                      <span className="far fa-star text-warning"></span>
                      <span className="far fa-star text-warning"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <div className="card shadow h-100">
                  {/* <img className="rounded" src="assets/img/testimonials/profiles.png" alt="" /> */}
                  <div className="card-body column">
                    <h3 className="fw-bold">Za*****</h3>
                    <p className="text-secondary text-sm">"Dengan harga segitu sangat cocok sih untuk mahasiswa buat konsultasi pemrograman."</p>
                    <div className="d-flex justify-content-center">
                      <span className="fas fa-star text-warning"></span>
                      <span className="fas fa-star text-warning"></span>
                      <span className="fas fa-star text-warning"></span>
                      <span className="fas fa-star text-warning"></span>
                      <span className="far fa-star text-warning"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <div className="card shadow h-100">
                  {/* <img className="rounded" src="assets/img/testimonials/profiles.png" alt="" /> */}
                  <div className="card-body column">
                    <h3 className="fw-bold">Na*****</h3>
                    <p className="text-secondary text-sm">"Kadang garing, tapi masih oke lah, joss"</p>
                    <div className="d-flex justify-content-center">
                      <span className="fas fa-star text-warning"></span>
                      <span className="fas fa-star text-warning"></span>
                      <span className="far fa-star text-warning"></span>
                      <span className="far fa-star text-warning"></span>
                      <span className="far fa-star text-warning"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <div className="card shadow h-100">
                  {/* <img className="rounded" src="assets/img/testimonials/profiles.png" alt="" /> */}
                  <div className="card-body column">
                    <h3 className="fw-bold">In*****</h3>
                    <p className="text-secondary text-sm">"Mentornya baik, ngasi saran yang sesuai algoritma dan nyantai."</p>
                    <div className="d-flex justify-content-center">
                      <span className="fas fa-star text-warning"></span>
                      <span className="fas fa-star text-warning"></span>
                      <span className="fas fa-star text-warning"></span>
                      <span className="far fa-star text-warning"></span>
                      <span className="far fa-star text-warning"></span>
                    </div>
                  </div>
                </div>
              </div>

              {/* buatin pagination preveous next dan angka */}
              {/* <div className="col-12 mt-4">
                <div className="d-flex justify-content-center">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      <li className="page-item">
                        <a className="page-link" href="#!" aria-label="Previous">
                          <span aria-hidden="true">«</span>
                        </a>
                      </li>
                      <li className="page-item"><a className="page-link" href="#!">1</a></li>
                      <li className="page-item"><a className="page-link" href="#!">2</a></li>
                      <li className="page-item"><a className="page-link" href="#!">3</a></li>
                      <li className="page-item">
                        <a className="page-link" href="#!" aria-label="Next">
                          <span aria-hidden="true">»</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div> */}
            </div>
          </div>

        </section>
      </div>

      <a  className="whats-app" href="https://api.whatsapp.com/send?phone=6285234637956&text=" rel="noreferrer" target="_blank">
        <img src="assets/svg/icons/whatsapp.svg" alt="" className="pt-2"/>
      </a>

      <FooterComponent />
    </>
  )
}

export default labsPage