import React from 'react'

const contactContentLanding = () => {
  return (
    <section className="py-md-11 py-6" id="contact">
      <div className="bg-holder z-index--1 bottom-0 d-none d-lg-block background-position-top holder-top">
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 text-center">
            <h1 className="fw-bold mb-4 fs-7">Mau tanya lebih lanjut?</h1>
            <p className="mb-5">Apakah Anda memerlukan bantuan untuk proyek Anda: pembuatan <br />
              website, aplikasi, prototipe, strategi pemasaran, halaman arahan, UX/UI?</p>
            <a href="https://api.whatsapp.com/send?phone=6285234637956&text=" rel="noreferrer" target="_blank" className="btn btn-warning btn-md">Kontak Kami Sekarang</a>
          </div>
        </div>
      </div>

    </section>
  )
}

export default contactContentLanding