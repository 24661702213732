import React from 'react'

const infoContentLanding = () => {
  return (
    <div id="info">
      <section className="pt-5" id="software-dev">

        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h5 className="text-secondary">Validasi Mudah untuk</h5>
              <h2 className="mb-2 fs-7">Software Development</h2>
              <p className="mb-4 fw-medium text-secondary">
                Halaman(pages) juga mendefinisikan individu, karakteristik, sifat, pilihan pribadi, 
                dan keseluruhannya kepribadian orang tersebut.
              </p>
              <h4 className="fs-1 fw-bold">Multi-Platform</h4>
              <p className="mb-4 fw-medium text-secondary">Menyediakan pembuatan software yang multi-platform <br /> seperti Web, Desktop dan Mobile Apps.</p>
              <h4 className="fs-1 fw-bold">Sesuai Permintaan</h4>
              <p className="mb-4 fw-medium text-secondary">Menyesuaikan dengan permintaan dari klien <br />untuk mendapatkan hasil yang maksimal dan sesuai,</p>
              <h4 className="fs-1 fw-bold">Layanan Pemeliharaan</h4>
              <p className="mb-4 fw-medium text-secondary">Software membutuhkan sebuah pemeliharaan untuk<br /> kesesuaian dengan kebutuhan fungsionalitas teknis software.</p>
            </div>
            <div className="col-lg-6"><img className="img-fluid" src="assets/svg/undraw/website.svg" alt="" /></div>
          </div>
        </div>

      </section>

      <section className="pt-5" id="mobile-dev">

        <div className="container">
          <div className="row">
            <div className="col-lg-6"><img className="img-fluid" src="assets/svg/undraw/mobile.svg" alt="" /></div>
            <div className="col-lg-6">
              <h5 className="text-secondary">Pengambilan keputusan yang lebih mudah untuk</h5>
              <p className="fs-7 fw-bold mb-2">Mobile Profesional</p>
              <p className="mb-4 fw-medium text-secondary">
                Mewujudkan ide-idemu pada layar kecil. Dengan setiap ketukan
                <br />dan gesekan, aplikasimu akan bersinar terang seperti permata.
              </p>
              <div className="d-flex align-items-center mb-3"> <img className="me-sm-4 me-2" src="assets/svg/ticks/ticks.svg" width="35" alt="tick" />
                <p className="fw-medium mb-0 text-secondary">Teknologi yang digunakan<br />Java Android Studio, Flutter, Kotlin dan React Native.</p>
              </div>
              <div className="d-flex align-items-center mb-3"> <img className="me-sm-4 me-2" src="assets/svg/ticks/ticks.svg" width="35" alt="tick" />
                <p className="fw-medium mb-0 text-secondary">Kemampuan untuk menciptakan aplikasi yang dapat diakses <br />dan digunakan di mana saja Android maupun iOS</p>
              </div>
              <div className="d-flex align-items-center mb-3"><img className="me-sm-4 me-2" src="assets/svg/ticks/ticks.svg" width="35" alt="tick" />
                <p className="fw-medium mb-0 text-secondary"> Keahlian teknis dan pemahaman kebutuhan pengguna<br /> untuk menciptakan aplikasi yang berkualitas tinggi.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5" id="design-dev">

        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h5 className="text-secondary">Memberikan pengalaman pengguna untuk</h5>
              <p className="mb-2 fs-8 fw-bold">UI/UX Desain Profesional</p>
              <p className="mb-4 fw-medium text-secondary">
                Beberapa akan berpendapat bahwa, terlepas dari kemajuan <br />
                teknologi, pengguna masih menghadapi standar ganda.
              </p>
              <h4 className="fw-bold fs-1">Tools yang digunakan</h4>
              <p className="mb-4 fw-medium text-secondary">Tools yang digunakan untuk desain UI/UX seperti <br />Adobe XD, Figma dan Illustrator</p>
              <h4 className="fw-bold fs-1">Desain Modern</h4>
              <p className="mb-4 fw-medium text-secondary">Desain yang sudah modern dan minimalis <br /> yang disesuaikan dengan permintaan klien.</p>
              <h4 className="fw-bold fs-1">Free Revisi</h4>
              <p className="mb-4 fw-medium text-secondary">Bebas revisi untuk memberikan kepuasan untuk memperbaiki<br /> dan melakukan perubahan untuk hasil yang lebih baik.</p>
            </div>
            <div className="col-lg-6"><img className="img-fluid" src="assets/svg/undraw/uiux.svg" alt="" /></div>
          </div>
        </div>

      </section>
    </div>
  )
}

export default infoContentLanding