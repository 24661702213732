import React from 'react'
import { ServiceContent, ContactContent, PortofolioContent, InfoContentLanding } from './landing'

const landingContent = () => {
  return (
    <div className="content">
      <ServiceContent />
      <InfoContentLanding />
      <ContactContent />
      {/* <PortofolioContent /> */}
      <a  className="whats-app" href="https://api.whatsapp.com/send?phone=6285234637956&text=" rel="noreferrer" target="_blank">
        <img src="assets/svg/icons/whatsapp.svg" alt="" className="pt-2"/>
      </a>
    </div>
  )
}

export default landingContent